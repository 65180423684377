import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Rates } from '../../shared/model/rates';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RatesSettingsService {

  constructor(private http: HttpClient) { }
  getRatesSettings(): Observable<Array<Rates>> {
    return this.http.get<Array<Rates>>(`/v0/rates`);
  }

  saveDefaultSettings(settings): Observable<string> {
    return this.http.post<string>(`/v0/rates`, settings, { responseType: 'text' as 'json'});
  }
}
