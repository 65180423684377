import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from './../shared/shared.module';
import { FooterComponent } from './containers/components/footer/footer.component';
import { NavBarComponent } from './containers/components/nav-bar/nav-bar.component';
import { SidebarComponent } from './containers/components/sidebar/sidebar.component';
import { ShellComponent } from './containers/shell/shell.component';
import { LogoComponent } from './containers/components/logo/logo.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FontAwesomeModule,
    AgGridModule.withComponents([])
  ],
  declarations: [
    FooterComponent,
    SidebarComponent,
    NavBarComponent,
    ShellComponent,
    LogoComponent
  ],
  exports: [
    FooterComponent,
    SidebarComponent,
    NavBarComponent,
    ShellComponent,
    LogoComponent
  ]
})
export class LayoutModule {}
