import { size, find } from 'lodash';
import * as moment from 'moment';

// const moment = require('moment').default || require('moment');

export const defaultColDef: any = {
  sortable: true,
  resizable: true,
  filter: true
};

const colDefprojectId = {
  headerName: 'projectId',
  field: 'id',
  width: 10,
  hide: true
};
const colDefProjectName = {
  field: 'projectName',
  headerName: 'Name',
  width: 200,
  comparator: caseInSensitiveComparator
};
const colDefClientName = {
  field: 'clientName',
  headerName: 'Client',
  width: 180,
  comparator: caseInSensitiveComparator
};
const colDefInitialValue = {
  headerName: 'Initial Value',
  field: 'initialValue',
  width: 150,
  valueGetter(params) {
    const startDate = moment([
      moment(params.data.weeksActual[0].creationDate).year(),
      moment(params.data.weeksActual[0].creationDate).month(),
      1
    ]).format('YYYY-MM-DD');
    return find(params.data.weeks, { creationDate: startDate })
      ? find(params.data.weeks, { creationDate: startDate }).weekValue
      : 0;
  }
};
export const colDefWeek1 = {
  headerName: 'Week 1',
  field: 'projectId',
  width: 150,
  cellClass(params) {
    return params.value > 0 ? 'text-success' : 'text-danger';
  },
  valueGetter(params) {
    const startDate = moment([
      moment(params.data.weeksActual[0].creationDate).year(),
      moment(params.data.weeksActual[0].creationDate).month(),
      8
    ]).format('YYYY-MM-DD');
    return find(params.data.weeks, { creationDate: startDate })
      ? find(params.data.weeks, { creationDate: startDate }).weekValue
      : 0;
  }
};
export const colDefWeek2 = {
  headerName: 'Week 2',
  field: 'projectId',
  width: 150,
  cellClass(params) {
    return params.value > 0 ? 'text-success' : 'text-danger';
  },
  valueGetter(params) {
    const startDate = moment([
      moment(params.data.weeksActual[0].creationDate).year(),
      moment(params.data.weeksActual[0].creationDate).month(),
      15
    ]).format('YYYY-MM-DD');
    return find(params.data.weeks, { creationDate: startDate })
      ? find(params.data.weeks, { creationDate: startDate }).weekValue
      : 0;
  }
};
export const colDefWeek3 = {
  headerName: 'Week 3',
  field: 'projectId',
  width: 150,
  cellClass(params) {
    return params.value > 0 ? 'text-success' : 'text-danger';
  },
  valueGetter(params) {
    const startDate = moment([
      moment(params.data.weeksActual[0].creationDate).year(),
      moment(params.data.weeksActual[0].creationDate).month(),
      22
    ]).format('YYYY-MM-DD');
    return find(params.data.weeks, { creationDate: startDate })
      ? find(params.data.weeks, { creationDate: startDate }).weekValue
      : 0;
  }
};
export const colDefWeek4 = {
  headerName: 'Week 4',
  field: 'projectId',
  width: 150,
  cellClass(params) {
    return params.value > 0 ? 'text-success' : 'text-danger';
  },
  valueGetter(params) {
    const startDate = moment([
      moment(params.data.weeksActual[0].creationDate).year(),
      moment(params.data.weeksActual[0].creationDate).month(),
      29
    ]).format('YYYY-MM-DD');

    return find(params.data.weeks, { creationDate: startDate })
      ? find(params.data.weeks, { creationDate: startDate }).weekValue
      : 0;
  }
};

export const colDefActualValue = {
  headerName: 'Actual Value',
  field: 'actualValue',
  width: 150,
  suppressColumnsToolPanel: true,
  valueGetter(params) {
    return params.data.weeksActual[size(params.data.weeksActual) - 1]
      ? params.data.weeksActual[size(params.data.weeksActual) - 1].weekValue
      : 0;
  }
};

export const overlayNoRecordTemplate = '<span class="ag-overlay-loading-center">No records found.</span>';
export let columnDefs = [colDefprojectId, colDefProjectName, colDefClientName, colDefInitialValue];

export function reinitGrid(lastAvailableWeek) {
  columnDefs = [colDefprojectId, colDefProjectName, colDefClientName, colDefInitialValue];
  for (let index = 0; index < lastAvailableWeek; index++) {
    if (index === 0) {
      columnDefs.push(colDefWeek1);
    } else if (index === 1) {
      columnDefs.push(colDefWeek2);
    } else if (index === 2) {
      columnDefs.push(colDefWeek3);
    } else if (index === 3) {
      columnDefs.push(colDefWeek4);
    }
  }
  columnDefs.push(colDefActualValue);
}

function caseInSensitiveComparator(valueA, valueB) {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}

function currencyFormatter(params) {
  if (params.value) {
    return '\u20AC ' + formatNumber(params.value);
  }
}

function numberFormatter(params) {
  return (
    Math.round(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '%'
  );
}

function formatNumber(value) {
  return Math.round(value)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function dateComparator(value1, value2) {
  if (value1 === null && value2 === null) {
    return 0;
  }
  if (value1 === null) {
    return -1;
  }
  if (value2 === null) {
    return 1;
  }
  return value1 - value2;
}
