<div class="card shadow h-100 py-2" [ngClass]="color">
  <div class="card-body">
    <div class="row no-gutters align-items-center">
      <app-loading *ngIf="showLoading;else content" class="w-100"></app-loading>
    </div>
  </div>
</div>
<ng-template #content>
  <div class="col mr-2">
    <fa-icon [icon]="['fas', cardIcon]" class="text-gray-300 mr-0 symbol-position-reset"></fa-icon>
    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">{{cardText}}</div>
    <div *ngIf="isHistory;else dashboardCard">
      <div class="font-weight-bold card-body__revenue-split text-uppercase text-dark" *ngIf="historyInitial">
        Initial
        : {{ historyInitial |  currency: 'EUR'}}</div>
      <div class="font-weight-bold card-body__revenue-split text-uppercase"
        [ngClass]="(historyActual >= historyInitial)?'text-success':'text-danger'" *ngIf="historyActual">
        Actual
        : {{ historyActual |  currency: 'EUR'}}</div>
    </div>
    <ng-template #dashboardCard>
      <div class="h5 mb-0 font-weight-bold text-gray-800 mb-2">{{showCardValue}}</div>
      <div class="font-weight-bold card-body__revenue-split text-uppercase text-primary" *ngIf="professionalRevenue">
        Prof
        : {{ professionalRevenue |  currency: 'EUR'}}</div>
      <div class="font-weight-bold card-body__revenue-split text-uppercase text-primary" *ngIf="indiaRevenue">India :
        {{ indiaRevenue |  currency: 'EUR'}}</div>
    </ng-template>
  </div>
  <div *ngIf="isHistory;else dashboardPercentage">
    <div class="card-body__percentage" [ngClass]="(historyActual >= historyInitial)?'text-success':'text-danger'">
      {{getHistoryPercentage()}}%
    </div>
  </div>
  <ng-template #dashboardPercentage>
    <div class="card-body__percentage text-muted" *ngIf="showPercentage">
      {{percentage}}%
    </div>
  </ng-template>
</ng-template>
