<div class="d-sm-flex align-items-center justify-content-between mb-4">
  <h1 class="h3 mb-0 text-gray-800">History</h1>
</div>

<div class="row mt-4 disableSelection">
  <!-- Revenue -->
  <div class="col-xl-12 col-lg-16 col-md-24 col-sm-24">
    <form [formGroup]="filterForm" class="col border-left-info shadow filter">
      <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Date</label>
      <div class="d-flex pb-2">
        <input formControlName="date" autocomplete="off" class="form-control mr-2" name="date" bsDatepicker
          [bsConfig]="{dateInputFormat: 'MM/YYYY'}" (onShown)="onOpenCalendar($event)">
        <app-split-button icon="search" text="Search" class="mr-2" (click)="onSearch()"></app-split-button>
        <app-split-button icon="file-export" [color]="splitButtonColor.SECONDARY" text="Export" class="mr-2"
          (click)="onExport()">
        </app-split-button>
      </div>
    </form>
  </div>
</div>

<div class="row mt-4">
  <!-- Revenue -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.SUCCESS" [cardText]="'Revenue'" [isHistory]="true" [isCardAmount]="true"
      cardIcon="euro-sign" [showLoading]="showLoading" [historyInitial]="revenueInitial"
      [historyActual]="revenueActual">
    </app-summary-card>
  </div>

  <!-- Billable -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.SUCCESS" [cardText]="'Billable'" [isHistory]="true" cardIcon="calendar"
      [showLoading]="showLoading" [historyInitial]="billableInitial" [historyActual]="billableActual">
    </app-summary-card>
  </div>

  <!-- Non Billable -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.WARNING" [cardText]="'Non Billable'" [isHistory]="true"
      cardIcon="calendar" [showLoading]="showLoading" [historyInitial]="nonBillableInitial"
      [historyActual]="nonBillableActual">
    </app-summary-card>
  </div>

  <!-- Scheduled Hours -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.SUCCESS" [cardText]="'Scheduled Hours'" [isHistory]="true"
      cardIcon="calendar" [showLoading]="showLoading" [historyInitial]="scheduledHoursInitial"
      [historyActual]="scheduledHoursActual">
    </app-summary-card>
  </div>

  <!-- Unscheduled Hours -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.WARNING" [cardText]="'Unscheduled Hours'" [isHistory]="true"
      cardIcon="calendar" [showLoading]="showLoading" [historyInitial]="unScheduledHoursInitial"
      [historyActual]="unScheduledHoursActual">
    </app-summary-card>
  </div>

  <!-- Available Hours -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.SUCCESS" [cardText]="'Available Hours'" [isHistory]="true"
      cardIcon="calendar" [showLoading]="showLoading" [historyInitial]="availableHoursInitial"
      [historyActual]="availableHoursActual">
    </app-summary-card>
  </div>
</div>

<app-history-listing [data]="tableInfo" *ngIf="!showLoading"></app-history-listing>
<app-table-loading *ngIf="showLoading"></app-table-loading>
