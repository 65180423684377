import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../model/user';
import { Router } from '@angular/router';
import { Constants } from '../../shared/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUser: Observable<any>;
  private currentUserSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(Constants.currentUser))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(username: string, password: string, useLocalStorage: boolean) {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password');
    return this.http
      .post<any>(`/oauth/token`, body.toString(), {
        headers: {
          Authorization: 'Basic ZmxvYXRBcHBVaTpGbDA0dF9MTA==',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .pipe(
        map(user => {
          if (user && user.access_token) {
            if (useLocalStorage) {
              localStorage.setItem(Constants.userEmail, username);
              localStorage.setItem(Constants.currentUser, JSON.stringify(user));
            }
            this.currentUserSubject.next(user);
          } else {
            return of(false);
          }
          return user;
        })
      );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(Constants.currentUser);
    localStorage.removeItem(Constants.userEmail);
    this.currentUserSubject.next(null);
  }
}
