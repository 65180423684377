<div class="modal-content">
  <div class="modal-header">
    <h4 id="dialog-sizes-name1" class="modal-title pull-left">Rates settings</h4>
    <button type="button" class="close pull-right" (click)="onCancel()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body h-100">
    <tabset>
      <tab heading="Basic title" id="tab1">
        <div class="row my-3 pl-3">
          <div class="col-6">
            <label>Default Rate</label>
          </div>
          <div class="col-18">
            <input type="number" class="form-control bg-light border-0 small col-18" [(ngModel)]="setting.Default"
              placeholder="Default rate">
          </div>
        </div>
        <hr *ngIf="departments">
        <div class="row my-3 pl-3" *ngFor="let department of departments">
          <div class="col-6">
            <label>{{department.name}}</label>
          </div>
          <div class="col-18">
            <input type="number" class="form-control bg-light border-0 small col-18" [(ngModel)]="setting[department.name]"
              placeholder="{{department.name}}">
          </div>
        </div>
        <hr *ngIf="departments">
        <div class="row my-3 pl-3">
          <div class="col-12">
            <app-split-button icon="save" text="Save" class="mr-2" (click)="saveDefaultSettings()"></app-split-button>
          </div>
        </div>
      </tab>
      <tab heading="Rates for clients">
        <div class="row my-3 pl-3">
          <div class="col-6">
            <label>Clients</label>
          </div>
          <div class="col-18">
            <ng-select [items]="clients" bindLabel="name" bindValue="id" [(ngModel)]="clientId"
              (ngModelChange)="clientChange()">
            </ng-select>
          </div>
        </div>
        <hr *ngIf="departments">
        <div class="row my-3 pl-3" *ngFor="let department of departments">
          <div class="col-6">
            <label>{{department.name}}</label>
          </div>
          <div class="col-18">
            <input type="number" class="form-control bg-light border-0 small col-18"
              [(ngModel)]="clientRateSetting[department.name]" placeholder="{{department.name}}">
          </div>
        </div>
        <hr>
        <div class="row my-3 pl-3">
          <div class="col-12">
            <app-split-button icon="save" text="Save" class="mr-2" (click)="saveClientRate()"></app-split-button>
          </div>
        </div>
      </tab>
      <tab heading="Rates overview" (click)="getRatesForClients()">
        <ag-grid-angular [rowData]="rowData" style="height: 500px;" class="ag-theme-material mt-3"
          [columnDefs]="columnDefs$ | async" *ngIf="rowData">
        </ag-grid-angular>
      </tab>
    </tabset>
  </div>
</div>
<notifier-container></notifier-container>
