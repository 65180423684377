<div class="d-sm-flex align-items-center justify-content-between mb-4">
  <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
  <div class="filter-container d-flex">
    <div class="button_cont" (click)="toggleOptions('tile')">
      <a class="top-button" [ngClass]="(showTile)?'':'top-button--disabled'"><span>Header</span></a>
    </div>
    <div class="button_cont" (click)="toggleOptions('filter')">
      <a class="top-button" [ngClass]="(showFilter)?'':'top-button--disabled'"><span>Filter</span></a>
    </div>
  </div>
</div>
<div class="row cards-row" [ngClass]="(showTile)?'show-options':'hide-options'">
  <!-- Revenue -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-24 mb-4">
    <app-revenue-card [color]="summaryCardColor.SUCCESS" [cardText]="'Revenue (Total)'" [cardValue]="revenuesSum"
    [professionalRevenue]="professionalRevenue" [indiaRevenue]="indiaRevenue" [isCardAmount]="true"
    cardIcon="euro-sign" [showLoading]="showLoading" [billable]="billableSum" [percentage]="billableHoursPercentage"
    showPercentage="true" ></app-revenue-card>
  </div>

  <!-- Confirmed -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-24 mb-4">
    <app-revenue-card [color]="summaryCardColor.SUCCESS" cardText="Revenue (Confirmed)" [cardValue]="confirmedSum"
    [professionalRevenue]="confirmedProfessionalRevenue" [indiaRevenue]="confirmedIndiaRevenue" [isCardAmount]="true"
    cardIcon="calendar" [showLoading]="showLoading" [billable]="billableConfirmedSum" [percentage]="billableConfirmedPercentage"
    showPercentage="true">
    </app-revenue-card>
  </div>

  <!-- Tentative -->
  <div class="col-xl-4 col-lg-8 col-md-8 col-sm-24 mb-4">
    <app-revenue-card [color]="summaryCardColor.WARNING" cardText="Revenue (Tentative)"  [cardValue]="tentativeSum"
    [professionalRevenue]="tentativeProfessionalRevenue" [indiaRevenue]="tentativeIndiaRevenue" [isCardAmount]="true"
      cardIcon="calendar" [showLoading]="showLoading" [billable]="billableTentativeSum" [percentage]="billableTentativePercentage"
      showPercentage="true">
    </app-revenue-card>
  </div>

  <!-- Non Billable -->
  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.WARNING" [cardText]="'Non Billable'" [cardValue]="nonBillableSum"
      cardIcon="calendar" [showLoading]="showLoading">
    </app-summary-card>
  </div>

  <!-- Scheduled Hours -->
  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.SUCCESS" [cardText]="'Scheduled Hours'" [cardValue]="scheduledHours"
      [percentage]="scheduledHoursPercentage" showPercentage="true" cardIcon="calendar" [showLoading]="showLoading">
    </app-summary-card>
  </div>

  <!-- Unscheduled Hours -->
  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.WARNING" [cardText]="'Unscheduled Hours'" [cardValue]="unscheduledHours"
      cardIcon="calendar" [percentage]="unscheduledHoursPercentage" showPercentage="true" [showLoading]="showLoading">
    </app-summary-card>
  </div>

  <!-- Available Hours -->
  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4">
    <app-summary-card [color]="summaryCardColor.SUCCESS" [cardText]="'Available Hours'" [cardValue]="availableHours"
      [percentage]="availableHoursPercentage" showPercentage="true" cardIcon="calendar" [showLoading]="showLoading">
    </app-summary-card>
  </div>
</div>

<div class="filter disableSelection shadow border-left-info" [ngClass]="(showFilter)?'show-options':'hide-options'">
  <form [formGroup]="filterForm" class="col">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-8 my-2">
        <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Date Range</label>
        <div class="float-right">
          <fa-icon [icon]="['fas', 'chevron-left']" class="fa-1x  text-gray-800 mr-4 icons" (click)="onPrevious()">
          </fa-icon>
          <fa-icon [icon]="['fas', 'chevron-right']" class="fa-1x  text-gray-800 icons" (click)="onNext()"></fa-icon>
        </div>
        <input type="text" placeholder="Select date range" class="form-control small" placement="right"
          formControlName="dateRange" [bsConfig]="bsConfig" bsDaterangepicker>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-8 my-2">
        <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Department</label>
        <app-input-select [data]="departments$ | async" dataValue="department_id"  dataLabel="name" [placeholder]="'Select department'"
          (event)="onChange($event,'department')"></app-input-select>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-8 my-2">
        <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Team Lead</label>
        <ng-select [items]="teamLeads$ | async" bindLabel="name" bindValue="id" (change)="onChange($event,'teamlead')">
        </ng-select>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-8 my-2">
        <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Status</label>
        <select formControlName="projectStatus" class="form-control small" (change)="search()">
          <option *ngFor="let status of projectStatus" [value]="status.id">
            {{ status.name }}
          </option>
        </select>
      </div>
      <div class=" col-xl-4 col-lg-4 col-md-8 my-2">
        <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Client</label>
        <app-input-select [data]="clients$ | async" dataValue="id" dataLabel="name" [placeholder]="'Select clients'"
          (event)="onChange($event,'client')"></app-input-select>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-8 my-2">
        <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Billable</label>
        <select formControlName="projectBillable" class="form-control small" (change)="search()">
          <option *ngFor="let item of billability" [value]="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-8 my-2">
        <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Probability</label>
        <input type="text" placeholder="From" class="form-control small" formControlName="ProbabilityFrom" min="1"
          max="100" (keyup)="ValidityProbablity($event, 'min')">
        <fa-icon [icon]="['fas', 'times-circle']" class="fa-1x text-gray-800 icons input__times-circle"
          *ngIf="formStatus().ProbabilityFrom.value" (click)="clearText('ProbabilityFrom')"></fa-icon>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-8 my-2">
        <label class="text-xs font-weight-bold text-primary text-uppercase mb-1">Probability</label>
        <input type="number" placeholder="To" class="form-control small" formControlName="ProbabilityTo" maxlength="3"
          min="1" max="100" (keyup)="ValidityProbablity($event, 'max')">
        <fa-icon [icon]="['fas', 'times-circle']" class="fa-1x text-gray-800 icons input__times-circle"
          *ngIf="formStatus().ProbabilityTo.value" (click)="clearText('ProbabilityTo')"></fa-icon>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-8 reset-margin-top">
        <app-split-button icon="search" text="Search" class="mr-2" (click)="search()"></app-split-button>
        <app-split-button icon="file-export" [color]="splitButtonColor.SECONDARY" (click)="export()" text="Export"
          class="mr-2">
        </app-split-button>
      </div>
    </div>
  </form>
</div>

<app-projects-listing [data]="projects" (probablityChanged)="probablityChanged($event)" *ngIf="!showLoading">
</app-projects-listing>
<app-table-loading *ngIf="showLoading"></app-table-loading>
<notifier-container></notifier-container>
