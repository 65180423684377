<nav class="navbar navbar-expand-sm navbar-light  bg-white topbar mb-4 static-top shadow">
  <div class="collapse navbar-collapse">
    <a class="navbar-brand" href="#">
      <img src="assets/EuroFloat-TxtLogo.png" alt="Logo" class="logo">
    </a>
    <ul class="navbar-nav ml-auto mt-2 menu">
      <li class="nav-item active">
        <a class="nav-link menu-item" routerLink="/dashboard" [routerLinkActive]="['is-active']">Dashboard <span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link menu-item" routerLink="/history" [routerLinkActive]="['is-active']">History</a>
      </li>
    </ul>
  </div>
  <div class="avatar-box">
    <div class="topbar-divider d-none d-sm-block"></div>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown no-arrow">
        <a class="nav-link dropdown-toggle dropdown-link" id="userDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" (click)="showUserDropdown()">
          <div class="avatar-box">
            <app-avatar [email]="userEmail" size="32" class="avatar-placement"></app-avatar>
            <span class="ml-2 d-none d-lg-inline text-gray-600 small avatar-box--username">{{ userName }}</span>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in show" [class.show]="showDropdown"
          aria-labelledby="userDropdown">
          <a class="dropdown-item" (click)="onRatesModel()">
            <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
            Rates
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" data-toggle="modal" (click)="onLogout()">
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Logout
          </a>
        </div>
      </li>
    </ul>
  </div>

</nav>


<!-- <nav class="navbar navbar-toggleable-xl navbar-inverse navbar-light  bg-white topbar mb-4 static-top shadow">
  <div class="container">
    <a class="navbar-brand">
      <img src="assets/EuroFloat-TxtLogo.png" alt="Logo" class="logo">
    </a>
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link menu-item" routerLink="/dashboard" [routerLinkActive]="['is-active']">Dashboard <span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link menu-item" routerLink="/history" [routerLinkActive]="['is-active']">History</a>
      </li>
    </ul>
    <div class="topbar-divider d-none d-sm-block"></div>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown no-arrow">
        <a class="nav-link dropdown-toggle dropdown-link" id="userDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" (click)="showUserDropdown()">
          <div class="avatar-box">
            <app-avatar [email]="userEmail" size="32" class="avatar-placement"></app-avatar>
            <span class="ml-2 d-none d-lg-inline text-gray-600 small avatar-box--username">{{ userName }}</span>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in show" [class.show]="showDropdown"
          aria-labelledby="userDropdown">
          <a class="dropdown-item" (click)="onRatesModel()">
            <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
            Rates
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" data-toggle="modal" (click)="onLogout()">
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Logout
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav> -->