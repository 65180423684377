import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AuthenticationService } from './../../../../core/services/Authentication.service';
import { RatesSettingsComponent } from './../../../../dashboard/components/rates-settings/rates-settings.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  showDropdown = false;
  showMenu = false;
  public modalRef: BsModalRef;
  public userEmail: string;
  public userName: string;

  constructor(
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userEmail = localStorage.getItem('userEmail');
    this.userName = this.formatUserName();
  }

  showUserDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  showMenuDropdown() {
    this.showMenu = !this.showMenu;
  }

  onLogout() {
    this.authenticationService.logout();
    this.router.navigateByUrl('login');
  }

  onRatesModel() {
    this.modalRef = this.modalService.show(RatesSettingsComponent, {
      class: 'modal-lg'
    });
    this.modalRef.content.onClose.subscribe();
  }

  formatUserName() {
    try {
      this.userName = this.userEmail
        .substring(0, this.userEmail.lastIndexOf('@'))
        .replace('.', ' ');
      return this.userName
        .split(' ')
        .map(item => {
          item = _.capitalize(item);
          return item;
        })
        .join(' ');
    } catch (e) {}
  }
}
