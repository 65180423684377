<!-- Nav Item - Messages -->
<li class="nav-item dropdown no-arrow mx-1" (click)="showMessages()">
  <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown"
    aria-haspopup="true" aria-expanded="false">
    <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
    <!-- Counter - Messages -->
    <span class="badge badge-danger badge-counter">7</span>
  </a>
  <!-- Dropdown - Messages -->
  <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" [class.show]="show"
    aria-labelledby="messagesDropdown">
    <h6 class="dropdown-header">
      Message Center
    </h6>
    <a class="dropdown-item d-flex align-items-center" href="#">
      <div class="dropdown-list-image mr-3">
        <img class="rounded-circle" src="assets/user1.jpg" alt="">
        <div class="status-indicator bg-success"></div>
      </div>
      <div class="font-weight-bold">
        <div class="text-truncate">Hi there! I am wondering if you can help me with a problem I've been having.
        </div>
        <div class="small text-gray-500">Emily Fowler · 58m</div>
      </div>
    </a>
    <a class="dropdown-item d-flex align-items-center" href="#">
      <div class="dropdown-list-image mr-3">
        <img class="rounded-circle" src="assets/user2.jpg" alt="">
        <div class="status-indicator"></div>
      </div>
      <div>
        <div class="text-truncate">I have the photos that you ordered last month, how would you like them sent to
          you?</div>
        <div class="small text-gray-500">Jae Chun · 1d</div>
      </div>
    </a>
    <a class="dropdown-item d-flex align-items-center" href="#">
      <div class="dropdown-list-image mr-3">
        <img class="rounded-circle" src="assets/user3.jpg" alt="">
        <div class="status-indicator bg-warning"></div>
      </div>
      <div>
        <div class="text-truncate">Last month's report looks great, I am very happy with the progress so far, keep
          up the good work!</div>
        <div class="small text-gray-500">Morgan Alvarez · 2d</div>
      </div>
    </a>
    <a class="dropdown-item d-flex align-items-center" href="#">
      <div class="dropdown-list-image mr-3">
        <img class="rounded-circle" src="assets/user4.jpg" alt="">
        <div class="status-indicator bg-success"></div>
      </div>
      <div>
        <div class="text-truncate">Am I a good boy? The reason I ask is because someone told me that people say this
          to all dogs, even if they aren't good...</div>
        <div class="small text-gray-500">Chicken the Dog · 2w</div>
      </div>
    </a>
    <a class="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
  </div>
</li>
