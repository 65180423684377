import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { defaultColDef, overlayNoRecordTemplate, columnDefs } from './history-list-data';

@Component({
  selector: 'app-history-listing',
  templateUrl: './history-listing.component.html',
  styleUrls: ['./history-listing.component.scss']
})
export class HistoryListingComponent implements OnInit {
  @Input() data: any;
  public defaultColDef;
  public multiSortKey;
  public frameworkComponents;
  public overlayNoRecordTemplate;
  public paginationPageSize = 50;
  public pageSize = [10, 25, 50, 100];
  public rowHeight;
  private gridApi: GridApi;

  columnDefs = [];
  rowData = [];
  constructor(private currencyPipe: CurrencyPipe) {
    this.defaultColDef = defaultColDef;
    this.overlayNoRecordTemplate = overlayNoRecordTemplate;
    this.columnDefs = columnDefs;
    this.rowHeight = 30;
  }

  ngOnInit() {}

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onPageSizeChanged(event) {
    if (event.target.value) {
      this.gridApi.paginationSetPageSize(Number(event.target.value));
    } else {
      this.gridApi.paginationSetPageSize(this.paginationPageSize);
    }
  }
}
