import * as _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class ListData {
  defaultColDef: any = {
    sortable: true,
    resizable: true,
    filter: true
  };
  overlayNoRecordTemplate =
    '<span class="ag-overlay-loading-center">No records found.</span>';
  multiSortKey: 'ctrl';
  columnDefs = [
    {
      headerName: 'projectId',
      field: 'projectId',
      width: 10,
      hide: true,
      suppressColumnsToolPanel: true
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      valueGetter(params) {
        return params.data.name;
      },
      comparator: caseInSensitiveComparator
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 180,
      valueGetter(params) {
        return params.data.client;
      },
      comparator: caseInSensitiveComparator
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
      suppressColumnsToolPanel: true,
      valueGetter(params) {
        return _.capitalize(params.data.status);
      }
    },
    {
      field: 'probability',
      headerName: 'Probability',
      width: 140,
      comparator: dateComparator,
      valueFormatter: numberFormatter,
      editable(params) {
        return (
          params.node.data.status === 'TENTATIVE' ||
          params.node.data.status === 'CONFIRMED'
        );
      },
      valueSetter(params) {
        if (params.newValue) {
          if (params.node.data.status === 'CONFIRMED') {
            if (params.newValue > 89 && params.newValue < 101) {
              params.data.probability = params.newValue;
              return true;
            } else {
              return false;
            }
          } else if (params.node.data.status === 'TENTATIVE') {
            if (params.newValue > 0 && params.newValue < 101) {
              params.data.probability = params.newValue;
              return true;
            } else {
              return false;
            }
          }
        }
      }
    },
    {
      field: 'sum',
      headerName: 'Sum',
      width: 150,
      valueFormatter: currencyFormatter
    },
    {
      field: 'germany',
      headerName: 'Germany',
      width: 140,
      valueFormatter: currencyFormatter
    },
    {
      field: 'germany_externals',
      headerName: 'Germany Externals',
      width: 140,
      valueFormatter: currencyFormatter
    },
    {
      field: 'india',
      headerName: 'India',
      width: 140,
      valueFormatter: currencyFormatter
    },    {
      field: 'india_externals',
      headerName: 'India Externals',
      width: 140,
      valueFormatter: currencyFormatter
    },
    {
      field: 'nearshore_externals',
      headerName: 'Nearshore Externals',
      width: 140,
      valueFormatter: currencyFormatter
    },
    {
      field: 'students',
      headerName: 'Students',
      width: 140,
      valueFormatter: currencyFormatter
    },

    { field: 'scheduled', headerName: 'Scheduled', width: 140 },
    {
      field: 'billable',
      headerName: 'Billable',
      width: 125
    }
  ];
}

function caseInSensitiveComparator(valueA, valueB) {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}

function currencyFormatter(params) {
  if (params.value) {
    return '\u20AC ' + formatNumber(params.value);
  }
}

function numberFormatter(params) {
  return (
    Math.round(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '%'
  );
}

function formatNumber(value) {
  return Math.round(value)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function dateComparator(value1, value2) {
  if (value1 === null && value2 === null) {
    return 0;
  }
  if (value1 === null) {
    return -1;
  }
  if (value2 === null) {
    return 1;
  }
  return value1 - value2;
}
