import { APP_INITIALIZER, Provider } from '@angular/core';
import { iconsInitializer } from './icons.initializer';

export const initializerProviders: Array<Provider> = [
  {
    provide: APP_INITIALIZER,
    useFactory: iconsInitializer,
    multi: true
  }
];
