import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss']
})
export class SummaryCardComponent implements OnInit {
  public CardValue: string;
  private IsCardAmount: boolean;

  @Input() color: SummaryCardColor;
  @Input() cardText: string;
  @Input() percentage?: string;
  @Input() showPercentage = false;
  @Input() showLoading = false;
  @Input() cardIcon = 'calendar';
  @Input() professionalRevenue: number;
  @Input() indiaRevenue: number;

  @Input() isHistory = false;
  @Input() historyInitial: number;
  @Input() historyActual: number;
  @Input() set cardValue(value: string) {
    this.CardValue = value;
  }
  @Input() set isCardAmount(value: boolean) {
    this.IsCardAmount = value;
  }

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit() {}

  get showCardValue() {
    return this.IsCardAmount
      ? this.currencyPipe.transform(this.CardValue, 'EUR', 'symbol-narrow', '0.2-2')
      : this.currencyPipe.transform(this.CardValue, '', '', '0.0-0');
  }

  getHistoryPercentage() {
    if (this.historyInitial && this.historyActual) {
      return (((this.historyActual - this.historyInitial) / this.historyActual) * 100).toFixed(2);
    } else {
      return '0';
    }
  }
}

export enum SummaryCardColor {
  PRIMARY = 'border-left-primary',
  SECONDARY = 'border-left-secondary',
  SUCCESS = 'border-left-success',
  DANGER = 'border-left-danger',
  INFO = 'border-left-info',
  WARNING = 'border-left-warning',
  LIGHT = 'border-left-light',
  DARK = 'border-left-dark'
}
