import { RevenueCardComponent } from './components/revenue-card/revenue-card.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { GravatarModule } from 'ngx-gravatar';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { InlineSpinnerComponent } from './components/inline-spinner/inline-spinner.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ListData } from './components/projects-listing/list-data';
import { ProjectsListingComponent } from './components/projects-listing/projects-listing.component';
import { SplitButtonComponent } from './components/split-button/split-button.component';
import { SummaryCardComponent } from './components/summary-card/summary-card.component';
import { TableLoadingComponent } from './components/table-loading/table-loading.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgSelectModule,
    ReactiveFormsModule,
    GravatarModule,
    AgGridModule.withComponents([])
  ],
  declarations: [
    SummaryCardComponent,
    SplitButtonComponent,
    InputSelectComponent,
    InlineSpinnerComponent,
    ProjectsListingComponent,
    TableLoadingComponent,
    MessagesComponent,
    AlertsComponent,
    AvatarComponent,
    LoadingComponent,
    RevenueCardComponent
  ],
  exports: [
    SummaryCardComponent,
    SplitButtonComponent,
    InputSelectComponent,
    InlineSpinnerComponent,
    ProjectsListingComponent,
    TableLoadingComponent,
    MessagesComponent,
    AlertsComponent,
    AvatarComponent,
    LoadingComponent,
    RevenueCardComponent
  ],
  providers: [CurrencyPipe, ListData]
})
export class SharedModule {}
