import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { SplitButtonColor } from '../shared/components/split-button/split-button.component';
import { SummaryCardColor } from '../shared/components/summary-card/summary-card.component';
import { SelectTypes } from '../shared/enum/SelectTypes.enum';
import { DashboardService } from './service/dashboard.service';
import { Project } from '../shared/model/overview';
import { round } from 'lodash';

enum ProjectStatus {
  Confirmed = 'CONFIRMED',
  Tentative = 'TENTATIVE'
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public summaryCardColor = SummaryCardColor;
  public splitButtonColor = SplitButtonColor;
  public departments$: Observable<any>;
  public departments: Array<number>;
  public clients$: Observable<any>;
  public clients: Array<number>;
  public teamLeads$: Observable<any>;
  public teamLeads: string;
  public projects: Project;

  public availableHours: number;
  public billableSum: number;
  public billableConfirmedSum: number;
  public billableConfirmedPercentage: string;
  public billableTentativeSum: number;
  public billableTentativePercentage: string;
  public billableHoursPercentage: string;
  public nonBillableSum: number;
  public revenuesSum: number;

  public confirmedSum = 0;
  public tentativeSum = 0;

  public scheduledHours: number;
  public unscheduledHours: number;
  public professionalRevenue = 0;
  public indiaRevenue = 0;

  public confirmedProfessionalRevenue = 0;
  public confirmedIndiaRevenue = 0;
  public tentativeProfessionalRevenue = 0;
  public tentativeIndiaRevenue = 0;

  public availableHoursPercentage: string;
  public unscheduledHoursPercentage: string;
  public scheduledHoursPercentage: string;
  public billablePercentage: string;
  public nonBillablePercentage: string;
  public showLoading = false;
  public showFilter = true;
  public showTile = true;

  public bacd: string;

  projectStatus = [
    { id: 'all', name: 'All' },
    { id: 'confirmed', name: 'Confirmed' },
    { id: 'tentative', name: 'Tentative' }
  ];

  billability = [
    { id: 'all', name: 'All' },
    { id: 'true', name: 'Billable' },
    { id: 'false', name: 'Non-Billable' }
  ];

  bsConfig: Partial<BsDatepickerConfig>;
  filterForm: FormGroup;
  selectedDate = new Date();

  constructor(private fb: FormBuilder, private dashboardService: DashboardService, private notifier: NotifierService) {
    this.filterForm = this.fb.group({
      department: [''],
      projectStatus: ['all'],
      projectBillable: ['all'],
      dateRange: [null],
      ProbabilityFrom: [null, [Validators.min(1), Validators.max(100)]],
      ProbabilityTo: [null, [Validators.min(1), Validators.max(100)]]
    });
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-dark-blue',
        isAnimated: true,
        adaptivePosition: true,
        rangeInputFormat: 'YYYY-MM-DD',
        useUtc: true
      }
    );
    this.selectedDate.setDate(1);
    this.findMonthRange();
  }

  findMonthRange() {
    let firstDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1);
    let lastDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0);

    firstDay = new Date(firstDay.getTime() - firstDay.getTimezoneOffset() * 60 * 1000);
    lastDay = new Date(lastDay.getTime() - lastDay.getTimezoneOffset() * 60 * 1000);
    this.setDateRange(firstDay, lastDay);
  }

  formStatus() {
    return this.filterForm.controls;
  }

  toggleOptions(option) {
    if (option === 'tile') {
      this.showTile = !this.showTile;
    } else {
      this.showFilter = !this.showFilter;
    }
  }

  clearText(control) {
    this.filterForm.controls[control].setValue('');
  }

  ngOnInit() {
    this.departments$ = this.dashboardService.getDepartment();
    this.clients$ = this.dashboardService.getClients();
    this.teamLeads$ = this.dashboardService.getTeamLeads();
    this.search();
  }

  onChange($event, mode) {
    if (mode === SelectTypes.DEPARTMENT) {
      this.departments = $event;
    } else if (mode === SelectTypes.CLIENT) {
      this.clients = $event;
    } else if (mode === SelectTypes.TEAMLEAD) {
      this.teamLeads = $event;
    }
    this.search();
  }

  onPrevious() {
    this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
    this.findMonthRange();
    this.search();
  }
  onNext() {
    this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
    this.findMonthRange();
    this.search();
  }

  setDateRange(start, end) {
    this.filterForm.patchValue({
      dateRange: [start, end]
    });
  }

  search() {
    this.showLoading = true;
    const queryStr = this.prepareSearchData();
    this.dashboardService.getOverview(queryStr).subscribe((projectdata) => {
      this.availableHours = projectdata.headerInformation.availableHours;
      this.nonBillableSum = projectdata.headerInformation.nonBillableSum;
      this.billableSum = round(projectdata.headerInformation.billableSum);
      this.revenuesSum = projectdata.headerInformation.revenuesSum;
      this.scheduledHours = projectdata.headerInformation.scheduledHours;
      this.unscheduledHours = projectdata.headerInformation.unscheduledHours;
      this.billableConfirmedSum = round(projectdata.headerInformation.billableConfirmedScheduledHours);
      this.billableTentativeSum = round(projectdata.headerInformation.billableTentativeScheduledHours);
      this.projects = this.formatProbablity(projectdata.projects);
      this.prepareRevenue(projectdata.projects);
      this.processPercentage(projectdata.headerInformation);
    });
  }

  formatProbablity(projects) {
    this.projects = projects.forEach((project) => {
      if (project.probability > 0) {
        project.probability = project.probability;
      } else {
        project.probability = 0;
      }
    });
    return projects;
  }

  processPercentage(info) {
    this.billableHoursPercentage = ((info.billableSum / info.availableHours) * 100).toFixed(2);
    this.availableHoursPercentage = ((info.availableHours / info.availableHours) * 100).toFixed(2);
    this.scheduledHoursPercentage = ((info.scheduledHours / info.availableHours) * 100).toFixed(2);
    this.unscheduledHoursPercentage = ((info.unscheduledHours / info.availableHours) * 100).toFixed(2);
    this.billablePercentage = ((info.billableSum / info.scheduledHours) * 100).toFixed(2);
    this.nonBillablePercentage = ((info.nonBillableSum / info.scheduledHours) * 100).toFixed(2);
    this.billableConfirmedPercentage = ((this.billableConfirmedSum / info.availableHours) * 100).toFixed(2);
    this.billableTentativePercentage = ((this.billableTentativeSum / info.availableHours) * 100).toFixed(2);
    this.showLoading = false;
  }

  prepareRevenue(projects) {
    this.professionalRevenue = 0;
    this.indiaRevenue = 0;
    this.confirmedSum = 0;
    this.tentativeSum = 0;
    this.confirmedProfessionalRevenue = 0;
    this.confirmedIndiaRevenue = 0;
    this.tentativeProfessionalRevenue = 0;
    this.tentativeIndiaRevenue = 0;


    projects.forEach((project) => {
      if (this.formStatus().projectStatus.value) {
        if (this.formStatus().projectStatus.value === 'all') {
          if (project.status === ProjectStatus.Confirmed) {
            this.confirmedSum += project.revenueSum;
            this.updateConfirmedRevenue(project);
          } else if (project.status === ProjectStatus.Tentative) {
            this.tentativeSum += project.revenueSum;
            this.updateTentativeRevenue(project);
          }
        } else if (this.formStatus().projectStatus.value === ProjectStatus.Confirmed.toLowerCase()) {
          this.confirmedSum += project.revenueSum;
          this.updateConfirmedRevenue(project);
        } else if (this.formStatus().projectStatus.value === ProjectStatus.Tentative.toLowerCase()) {
          this.tentativeSum += project.revenueSum;
          this.updateTentativeRevenue(project);
        }
      }
      if (project.revenueByDepartment.Professionals) {
        this.professionalRevenue += project.revenueByDepartment.Professionals;
      }
      if (project.revenueByDepartment.India) {
        this.indiaRevenue += Number(project.revenueByDepartment.India);
      }
    });
  }

  updateConfirmedRevenue(project) {
    if (project.revenueByDepartment.Professionals) {
      this.confirmedProfessionalRevenue += project.revenueByDepartment.Professionals;
    }
    if (project.revenueByDepartment.India) {
      this.confirmedIndiaRevenue += project.revenueByDepartment.India;
    }
  }

  updateTentativeRevenue(project) {
    if (project.revenueByDepartment.Professionals) {
      this.tentativeProfessionalRevenue += project.revenueByDepartment.Professionals;
    }
    if (project.revenueByDepartment.India) {
      this.tentativeIndiaRevenue += project.revenueByDepartment.India;
    }
  }

  prepareSearchData() {
    let body = new HttpParams();
    if (this.formStatus().dateRange.value[0]) {
      body = body.append('startDate', moment(this.formStatus().dateRange.value[0]).format('YYYY-MM-DD'));
    }
    if (this.formStatus().dateRange.value[1]) {
      body = body.append('endDate', moment(this.formStatus().dateRange.value[1]).format('YYYY-MM-DD'));
    }
    if (this.formStatus().projectStatus.value && this.formStatus().projectStatus.value !== 'all') {
      body = body.append('projectStatus', this.formStatus().projectStatus.value);
    }
    if (this.teamLeads) {
      body = body.append('tl', this.teamLeads);
    }
    if (this.departments) {
      body = body.append('departmentId', this.departments.join(','));
    }
    if (this.clients) {
      body = body.append('clientId', this.clients.join(','));
    }
    if (this.formStatus().projectBillable.value && this.formStatus().projectBillable.value !== 'all') {
      body = body.append('billable', this.formStatus().projectBillable.value);
    }
    if (this.formStatus().ProbabilityFrom.value && this.formStatus().ProbabilityFrom.value !== '') {
      body = body.append('probabilityMin', this.formStatus().ProbabilityFrom.value);
    }
    if (this.formStatus().ProbabilityTo.value && this.formStatus().ProbabilityTo.value !== '') {
      body = body.append('probabilityMax', this.formStatus().ProbabilityTo.value);
    }
    return body.toString();
  }

  export() {
    const queryStr = this.prepareSearchData();
    this.dashboardService.getExport(queryStr).subscribe(
      (resp) => {
        saveAs(resp.body, resp.headers.get('Content-Disposition').split('=')[1]);
      },
      (error) => {
        this.notifier.notify('info', 'Failed to download the report.');
      }
    );
  }

  probablityChanged(probablity) {
    this.dashboardService.updateProbablity(probablity).subscribe((res) => {
      this.notifier.notify('success', res);
    });
  }

  ValidityProbablity(event, ctrl) {
    if (parseInt(event.target.value, 2) < 0) {
      if (ctrl === 'min') {
        this.filterForm.patchValue({
          ProbabilityFrom: '1'
        });
      } else if (ctrl === 'max') {
        this.filterForm.patchValue({
          ProbabilityTo: '100'
        });
      }
      return false;
    }
    if (event.target.value > 100) {
      if (ctrl === 'min') {
        this.filterForm.patchValue({
          ProbabilityFrom: '1'
        });
      } else if (ctrl === 'max') {
        this.filterForm.patchValue({
          ProbabilityTo: 100
        });
      }
      return false;
    }
  }
}
