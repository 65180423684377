import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { SplitButtonColor } from '../../../shared/components/split-button/split-button.component';
import { DashboardService } from '../../service/dashboard.service';
import { RatesSettingsService } from '../../service/rates-settings.service';
import { Department } from '../../../shared/model/department';
import { Clients } from '../../../shared/model/clients';
import { Rates } from '../../../shared/model/rates';

type gridColumn = {
  headerName: string;
  field: string;
  sortable: boolean;
  filter: boolean;
  comparator: any
};

@Component({
  selector: 'app-rates-settings',
  templateUrl: './rates-settings.component.html',
  styleUrls: ['./rates-settings.component.scss']
})
export class RatesSettingsComponent implements OnInit {
  public splitButtonColor = SplitButtonColor;
  public departments$: Observable<any>;
  public clients$: Observable<any>;
  public teamLeads$: Observable<any>;
  public rateSettings$: Observable<any>;
  public onClose: Subject<boolean>;

  rates: Array<Rates>;
  ratesOverview: any[] = [];
  overview: any[] = [];
  clients: Array<Clients>;
  clientId: number;
  departments: Array<Department>;

  columnDefs$: Observable<Array<gridColumn>>;
  rowData = [];

  setting: any = {};
  clientRateSetting: any = {};

  constructor(
    private bsModalRef: BsModalRef,
    private dashboardService: DashboardService,
    private ratesSettingsService: RatesSettingsService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.columnDefs$ = this.setupColumnDefs(this.dashboardService);
    this.onClose = new Subject();
    this.dashboardService.getDepartment().subscribe((departments) => {
      if (departments) {
        this.departments = departments;
        this.loadRatesSettings();
      }
    });
  }

  setupColumnDefs(dashboardService: DashboardService): Observable<Array<gridColumn>> {
    return dashboardService.getDepartment().pipe(
      switchMap((departments) => {
        const tempColumnDefs: Array<gridColumn> = [
          { headerName: 'Client', field: 'clientName', sortable: true, filter: true, comparator: caseInSensitiveComparator }
        ];
        departments.forEach((department) => {
          tempColumnDefs.push({
            headerName: department.name,
            field: department.name,
            sortable: true,
            filter: true,
            comparator: null
          });
        });

        return of(tempColumnDefs);
      })
    );
  }

  loadRatesSettings() {
    this.rateSettings$ = this.ratesSettingsService.getRatesSettings();
    this.rateSettings$.subscribe();
    forkJoin([this.dashboardService.getClients(), this.ratesSettingsService.getRatesSettings()]).subscribe(
      ([clients, rates]) => {
        this.clients = clients;
        this.rates = rates;
        this.getRatesForClients();
        this.getDefaultRate(rates);
        this.getDefaultRatesForDepartments(rates);
        this.prepareTableData();
      }
    );
  }

  getDefaultRate(rates) {
    const defaultRates = rates.filter(
      rate => rate.clientId === undefined && rate.department === undefined
    );
    this.setting.Default = defaultRates[0].hourlyRate;
  }

  getDefaultRatesForDepartments(rates) {
    const defaultRates = rates.filter(
      rate => rate.clientId === undefined && rate.department !== undefined
    );

    for (const key in defaultRates) {
      if (defaultRates.hasOwnProperty(key)) {
        // check if default rates for department retrieved from database correspond to api.float department
        const departmentInBothRatesAndFloat = this.departments.find((floatDepartment) => {
          return floatDepartment.name === defaultRates[key].department;
        });
        if (departmentInBothRatesAndFloat !== undefined) {
          this.setting[defaultRates[key].department] = defaultRates[key].hourlyRate;
        }
      }
    }
  }

  getRatesForClients() {
    if (this.rates !== undefined && this.clients !== undefined) {
      const clientRates = this.rates.filter(
        rate => rate.clientId !== undefined
      );
      const uniqClientRates: any = _.uniqBy(clientRates, 'clientId');
      this.overview = [];
      for (const key in uniqClientRates) {
        try {
          const clientRateOverview: any = {};
          const ratesByClient = this.rates.filter(
            rate => rate.clientId === uniqClientRates[key].clientId
          );
          // tslint:disable-next-line: no-string-literal
          clientRateOverview['clientName'] = this.clients.filter(
            client => client.id === ratesByClient[0].clientId
          )[0].name;

          if (ratesByClient.length > 0) {
            // tslint:disable-next-line:forin
            for (const k in ratesByClient) {
              if (ratesByClient.hasOwnProperty(k)) {
                clientRateOverview[ratesByClient[k].department] = ratesByClient[k].hourlyRate;
                clientRateOverview[
                  ratesByClient[k].department]
                 = ratesByClient[k].hourlyRate;
              }
              // tslint:disable-next-line: radix
              if (parseInt(k) === ratesByClient.length - 1) {
                this.overview.push(clientRateOverview);
              }
            }
          }
        } catch (error) {}
      }
      this.prepareTableData();
    }
  }

  clientChange() {
    this.clientRateSetting = {};
    if (this.rates !== undefined) {
      const clientRates = this.rates.filter(
        rate => rate.clientId !== undefined
      );
      const defaultRates = clientRates.filter(
        rate => rate.clientId === this.clientId
      );
      if (defaultRates.length > 0) {
        for (const key in defaultRates) {
          if (defaultRates.hasOwnProperty(key)) {
            // check if client rates for department retrieved from database correspond to api.float department
            const departmentInBothRatesAndFloat = this.departments.find((floatDepartment) => {
              return floatDepartment.name === defaultRates[key].department;
            });
            if (departmentInBothRatesAndFloat !== undefined) {
              this.clientRateSetting[defaultRates[key].department] = defaultRates[key].hourlyRate;
            }
          }
        }
      } else {
        this.clientRateSetting = {};
      }
    }
  }

  prepareTableData() {
    this.rowData = this.overview;
  }

  saveDefaultSettings() {
    const rateData = [];
    let count = 0;
    for (const key in this.setting) {
      if (this.setting.hasOwnProperty(key)) {
        count++;
        if (key === 'Default') {
          if (
            this.setting.Default !== '' &&
            this.setting.Default !== undefined &&
            this.setting.Default !== 0 &&
            this.setting.Default !== null
          ) {
            rateData.push({
              hourlyRate: this.setting.Default
            });
          }
        } else {
          if (this.setting[key] !== '' &&
            this.setting[key] !== undefined &&
            this.setting[key] !== 0) {
            const departmentId = this.departments.filter((department) => department.name === key)[0].department_id;
            rateData.push({
              hourlyRate: this.setting[key],
              departmentId
            });
          }
        }
        if (count === Object.keys(this.setting).length) {
          if (rateData.length !== 0) {
            this.ratesSettingsService.saveDefaultSettings(rateData).subscribe(
              data => {
                this.notifier.notify('success', 'Rates saved successfully');
                this.loadRatesSettings();
              },
              err => {
                this.notifier.notify(
                  'error',
                  'Unable to save the Rates, please try again later.'
                );
                this.loadRatesSettings();
              }
            );
          }
        }
      }
    }
  }

  saveClientRate() {
    const rateData = [];
    let count = 0;
    // tslint:disable-next-line:forin
    for (const key in this.clientRateSetting) {
      count++;
      if (this.clientRateSetting.hasOwnProperty(key)) {
        if (
          this.clientRateSetting[key] !== '' &&
          this.clientRateSetting[key] !== undefined &&
          this.clientRateSetting[key] !== 0
        ) {
          const departmentId = this.departments.filter((department) => department.name === key)[0].department_id;

          rateData.push({
            hourlyRate: this.clientRateSetting[key],
            departmentId,
            clientId: this.clientId
          });
        }
        if (count === Object.keys(this.clientRateSetting).length) {
          if (rateData.length !== 0) {
            this.ratesSettingsService.saveDefaultSettings(rateData).subscribe(
              data => {
                this.loadRatesSettings();
                this.notifier.notify('success', 'Rates saved successfully');
              },
              err => {
                this.notifier.notify(
                  'error',
                  'Unable to save the Rates, please try again later.'
                );
              }
            );
          }
        }
      }
    }
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
function caseInSensitiveComparator(valueA, valueB) {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}
