<!-- Page Wrapper -->
<div id="wrapper" class="vh-100">
  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <app-nav-bar></app-nav-bar>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<!-- End of Page Wrapper -->