import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent {
  selectForm: FormGroup;
  @Input() data: any;
  @Input() dataValue: string;
  @Input() dataLabel: string;
  @Input() placeholder = 'Please select';
  @Input() multiple = true;

  @Output() event: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    this.selectForm = this.fb.group({
      selectMultiple: ''
    });
  }

  public onSelectAll() {
    const selected = this.data.map(item => item[this.dataValue] ? item[this.dataValue] : item);
    this.selectForm.get('selectMultiple').patchValue(selected);
    this.event.emit(selected);
  }

  public onClearAll() {
    this.selectForm.get('selectMultiple').patchValue([]);
    this.event.emit([]);
  }

  onChange() {
    this.event.emit(this.selectForm.get('selectMultiple').value);
  }
}
