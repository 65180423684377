<form [formGroup]="selectForm" novalidate>
  <ng-select [items]="data" [bindValue]="dataValue" [bindLabel]="dataLabel" [multiple]="multiple"
    [placeholder]="placeholder" formControlName="selectMultiple" (change)="onChange();">
    <ng-template ng-header-tmp>
      <div>
        <button class="btn btn-link" (click)="onSelectAll()">Select All</button>
        <button class="btn btn-link" (click)="onClearAll()">Clear All</button>
      </div>
    </ng-template>
  </ng-select>
</form>