<div class="project-container" style="width: 100%; height: 1500px;">
  <ag-grid-angular style="height: 100%; width: 100%;" class="ag-theme-material mt-3 mb-1" [rowData]="data"
    [pagination]="true" [paginationPageSize]="paginationPageSize" [columnDefs]="columnDefs" [rowHeight]="rowHeight"
    [defaultColDef]="defaultColDef" [multiSortKey]="multiSortKey" [overlayNoRowsTemplate]="overlayNoRecordTemplate"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
  <div class="project-footer d-none d-sm-block d-md-block d-lg-block d-xl-block">
    Page Size:
    <select (change)="onPageSizeChanged($event)">
      <option *ngFor="let opt of pageSize" [value]="opt" [selected]="opt===50">{{opt}}</option>
    </select>
  </div>
</div>