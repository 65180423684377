import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { Probablity } from '../../model/probablity.model';
import { ListData } from './list-data';
import * as _ from 'lodash';

@Component({
  selector: 'app-projects-listing',
  templateUrl: './projects-listing.component.html',
  styleUrls: ['./projects-listing.component.scss']
})
export class ProjectsListingComponent {
  @Input('data') set data(value) {
    this.prepareData(value);
  }
  @Output() probablityChanged = new EventEmitter<Probablity>();
  public defaultColDef;
  public multiSortKey;
  public frameworkComponents;
  public overlayNoRecordTemplate;
  public paginationPageSize = 50;
  public pageSize = [10, 25, 50, 100];
  public rowHeight;
  private gridApi: GridApi;

  columnDefs = [];
  rowData = [];

  constructor(private currencyPipe: CurrencyPipe, public listData: ListData) {
    this.defaultColDef = listData.defaultColDef;
    this.overlayNoRecordTemplate = listData.overlayNoRecordTemplate;
    this.multiSortKey = listData.multiSortKey;
    this.columnDefs = listData.columnDefs;
    this.rowHeight = 30;
  }

  onCellValueChanged(params) {
    const colId = params.column.getId();
    if (colId === 'probability') {
      const probability = params.data.probability;
      const projectId = params.data.projectId;
      if (probability !== 'N/A') {
        const probablityParams = {
          projectId,
          probability
        };
        this.probablityChanged.emit(probablityParams);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  prepareData(value) {
    this.rowData = [];
    if (value) {
      value.forEach((element) => {
        this.rowData = [
          ...this.rowData,
          {
            projectId: element.id,
            name: element.name,
            client: element.client.name,
            probability: element.probability,
            sum: element.revenueSum,
            india: element.revenueByDepartment.India,
            students: element.revenueByDepartment.Students,
            nearshore_externals: element.revenueByDepartment.Nearshore_Externals,
            germany: element.revenueByDepartment.Germany,
            india_externals: element.revenueByDepartment.India_Externals,
            germany_externals: element.revenueByDepartment.Germany_Externals,
            scheduled: element.scheduledHoursSum,
            billable: element.billable ? 'Yes' : '',
            status: element.status
          }
        ];
      });
    } else {
      return false;
    }
    this.rowData = _.orderBy(this.rowData, [row => row.client.toLowerCase()], ['asc']);
  }

  onPageSizeChanged(event) {
    if (event.target.value) {
      this.gridApi.paginationSetPageSize(Number(event.target.value));
    } else {
      this.gridApi.paginationSetPageSize(this.paginationPageSize);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
}
