import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { find, findIndex, forEach, size, ceil } from 'lodash';
import * as moment from 'moment';
import { SplitButtonColor } from './../../shared/components/split-button/split-button.component';
import { SummaryCardColor } from './../../shared/components/summary-card/summary-card.component';
import { HistoryInfo } from './../../shared/model/history-info';
import { reinitGrid } from './../components/history-listing/history-list-data';
import { HistoryService } from './../service/history.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  summaryCardColor = SummaryCardColor;
  splitButtonColor = SplitButtonColor;
  showLoading = false;

  revenueInitial = 0;
  revenueActual = 0;
  billableInitial = 0;
  billableActual = 0;
  nonBillableInitial = 0;
  nonBillableActual = 0;
  scheduledHoursInitial = 0;
  scheduledHoursActual = 0;
  unScheduledHoursInitial = 0;
  unScheduledHoursActual = 0;
  availableHoursInitial = 0;
  availableHoursActual = 0;

  weeksProjectInfo: any;
  tableInfo: Array<HistoryInfo> = [];

  selectedDate = new Date();
  filterForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private historyService: HistoryService
  ) {
    this.filterForm = this.fb.group({
      date: [null]
    });
  }

  ngOnInit() {}

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  formStatus() {
    return this.filterForm.controls;
  }

  onSearch() {
    const initialDate = this.formStatus().date.value;
    const monthStart = moment(initialDate).format('YYYY-MM-DD');
    this.weeksProjectInfo = [];
    this.showLoading = true;
    if (this.formStatus().date.value) {
      let body = new HttpParams();
      body = body.append('dateInMonth', monthStart);
      const queryStr = body.toString();
      this.historyService.getHistory(queryStr).subscribe((projectdata) => {
        this.weeksProjectInfo = projectdata;
        this.fillProjectTiles();
        this.prepareTableData();
        this.showLoading = false;
      });
    } else {
      this.showLoading = false;
    }
  }

  fillProjectTiles() {
    const lastAvailableWeek = size(this.weeksProjectInfo) - 1;
    if (lastAvailableWeek > 0) {
      reinitGrid(lastAvailableWeek);
    }
    this.availableHoursInitial = this.weeksProjectInfo[0]
      ? this.weeksProjectInfo[0].headerInformation.availableHours
      : 0;
    this.availableHoursActual = this.weeksProjectInfo[lastAvailableWeek]
      ? this.weeksProjectInfo[lastAvailableWeek].headerInformation.availableHours
      : 0;

    this.billableInitial = this.weeksProjectInfo[0] ? this.weeksProjectInfo[0].headerInformation.billableSum : 0;
    this.billableActual = this.weeksProjectInfo[lastAvailableWeek]
      ? this.weeksProjectInfo[lastAvailableWeek].headerInformation.billableSum
      : 0;

    this.nonBillableInitial = this.weeksProjectInfo[0] ? this.weeksProjectInfo[0].headerInformation.nonBillableSum : 0;
    this.nonBillableActual = this.weeksProjectInfo[lastAvailableWeek]
      ? this.weeksProjectInfo[lastAvailableWeek].headerInformation.nonBillableSum
      : 0;

    this.revenueInitial = this.weeksProjectInfo[0] ? this.weeksProjectInfo[0].headerInformation.revenuesSum : 0;
    this.revenueActual = this.weeksProjectInfo[lastAvailableWeek]
      ? this.weeksProjectInfo[lastAvailableWeek].headerInformation.revenuesSum
      : 0;

    this.scheduledHoursInitial = this.weeksProjectInfo[0]
      ? this.weeksProjectInfo[0].headerInformation.scheduledHours
      : 0;
    this.scheduledHoursActual = this.weeksProjectInfo[lastAvailableWeek]
      ? this.weeksProjectInfo[lastAvailableWeek].headerInformation.scheduledHours
      : 0;

    this.unScheduledHoursInitial = this.weeksProjectInfo[0]
      ? this.weeksProjectInfo[0].headerInformation.unscheduledHours
      : 0;
    this.unScheduledHoursActual = this.weeksProjectInfo[lastAvailableWeek]
      ? this.weeksProjectInfo[lastAvailableWeek].headerInformation.unscheduledHours
      : 0;
  }

  prepareTableData() {
    let creationDate: Date;
    this.tableInfo = [];
    forEach(this.weeksProjectInfo, (weekInfo, key) => {
      creationDate = weekInfo.creationDate;
      forEach(weekInfo.projects, (projectInfo, projectKey) => {
        if (findIndex(this.tableInfo, { id: projectInfo.id }) < 0) {
          this.tableInfo.push({
            id: projectInfo.id,
            projectName: projectInfo.name,
            clientName: projectInfo.client.name,
            weeksActual: [
              {
                week: key + 1,
                weekValue: projectInfo.revenueSum,
                creationDate: new Date(creationDate).toISOString().slice(0, 10)
              }
            ],
            actualValue: projectInfo.revenueSum,
            weeks: [
              {
                week: key + 1,
                weekValue: projectInfo.revenueSum,
                creationDate:  new Date(creationDate).toISOString().slice(0, 10)
              }
            ]
          });
        } else {
          const previousValue = find(this.tableInfo, ['id', projectInfo.id]).weeks[Number(key) - 1]
            ? find(this.tableInfo, ['id', projectInfo.id]).weeks[Number(key) - 1].weekValue
            : 0;
          find(this.tableInfo, ['id', projectInfo.id]).weeks.push({
            week: Number(key) + 1,
            weekValue: projectInfo.revenueSum - previousValue,
            creationDate: new Date(creationDate).toISOString().slice(0, 10)
          });
          find(this.tableInfo, ['id', projectInfo.id]).weeksActual.push({
            week: Number(key) + 1,
            weekValue: projectInfo.revenueSum,
            creationDate: new Date(creationDate).toISOString().slice(0, 10)
          });
        }
      });
    });

    forEach(this.tableInfo, (projectInfo, key) => {
      projectInfo.weeks = this.formatWeeks(projectInfo);
      projectInfo.weeksActual = this.formatWeeksActual(projectInfo);
    });

  }

  onExport() {}

  formatWeeks(projectInfo) {
    const formattedWeeks = [];
    const weeksAvailable = size(this.weeksProjectInfo);
    for (let index = 1; index <= weeksAvailable; index++) {
      find(projectInfo.weeks, ['week', index])
        ? formattedWeeks.push(find(projectInfo.weeks, ['week', index]))
        : formattedWeeks.push({
            week: index,
            weekValue: 0
          });
    }
    return formattedWeeks;
  }

  formatWeeksActual(projectInfo) {
    const formattedWeeks = [];
    const weeksAvailable = size(this.weeksProjectInfo);
    for (let index = 1; index <= weeksAvailable; index++) {
      find(projectInfo.weeksActual, ['week', index])
        ? formattedWeeks.push(find(projectInfo.weeksActual, ['week', index]))
        : formattedWeeks.push({
            week: index,
            weekValue: 0
          });
    }
    return formattedWeeks;
  }
}
