import { Component, OnInit, Input } from '@angular/core';
export enum SplitButtonColor {
  PRIMARY = 'btn-primary',
  SECONDARY = 'btn-secondary',
  SUCCESS = 'btn-success',
  DANGER = 'btn-danger',
  INFO = 'btn-info',
  WARNING = 'btn-warning',
  LIGHT = 'btn-light',
  DARK = 'btn-dark',
}

@Component({
  selector: 'app-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss']
})
export class SplitButtonComponent {
  @Input() icon: string;
  @Input() text: string;
  @Input() color: SplitButtonColor = SplitButtonColor.PRIMARY;
  constructor() { }

}

