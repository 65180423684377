<!-- Footer -->
<footer class="sticky-footer bg-white">
  <div class="container my-auto">
    <div class="copyright text-center my-auto">
      <span>Copyright (C) 1992 - 2020 logicline GmbH | All Rights Reserved</span> <br/>
      <div class="version-box">Version: 1.2 (07.01.2020) </div>
    </div>
  </div>
</footer>
<!-- End of Footer -->
