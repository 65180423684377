import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { NotifierModule } from 'angular-notifier';
import { TabsModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LayoutModule } from './../layout/layout.module';
import { notifierDefaultOptions } from './../shared/config/notification.config';
import { SharedModule } from './../shared/shared.module';
import { RatesSettingsComponent } from './components/rates-settings/rates-settings.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FontAwesomeModule,
    DashboardRoutingModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    AgGridModule.withComponents([])
  ],
  declarations: [DashboardComponent, RatesSettingsComponent],
  entryComponents: [RatesSettingsComponent]
})
export class DashboardModule { }
