import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Department } from '../../shared/model/department';
import { Clients } from '../../shared/model/clients';
import { Overview } from '../../shared/model/overview';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getDepartment(): Observable<Array<Department>> {
    return this.http.get<Array<Department>>(`/v0/departments`);
  }

  getClients(): Observable<Array<Clients>> {
    return this.http.get<Array<Clients>>(`/v0/clients`).pipe(
      map(clients => _.orderBy(clients, [client => client.name.toLowerCase()], ['asc']))
    );
  }

  getTeamLeads(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`/v0/tl`);
  }

  getOverview(queryStr: string): Observable<Overview> {
    return this.http.get<Overview>(
      `/v0/overview?${queryStr}`
    );
  }

  updateProbablity(probablity): Observable<string> {
    return this.http.post<string>(
      `/v0/probabilities`,
      { ...probablity },
      {
        responseType: 'text' as 'json'
      }
    );
  }

  getExport(queryStr: string): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `/v0/overview/export?${queryStr}`,
      { responseType: 'blob', observe: 'response' }
    );
  }
}
