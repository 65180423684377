<div class="card shadow h-100 p-2" [ngClass]="color">
  <div class="card-body">
      <app-loading *ngIf="showLoading;else content" class="w-100"></app-loading>
  </div>
</div>
<ng-template #content>
  <div class="row">
    <div class="col-15">
      <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">{{cardText}}</div>
      <div class="h5 mb-0 font-weight-bold text-gray-800 mb-1">{{showCardValue}}</div>
      <div class="font-weight-bold card-body__revenue-split text-uppercase text-primary" *ngIf="professionalRevenue">
        Prof
        : {{ professionalRevenue |  currency: 'EUR'}}</div>
      <div class="font-weight-bold card-body__revenue-split text-uppercase text-primary" *ngIf="indiaRevenue">India :
        {{ indiaRevenue |  currency: 'EUR'}}</div>
    </div>
    <div class="col-8 text-right">
      <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Billable</div>
      <div class="h5 mb-0 font-weight-bold text-gray-800 mb-1">{{billableCardValue}}</div>
    </div>
  </div>
  <div *ngIf="showPercentage" class="card-body__percentage">
    <div class="card-body__percentage text-muted">
      {{percentage}}%
    </div>
  </div>
</ng-template>
