import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Overview } from './../../shared/model/overview';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  constructor(private http: HttpClient) {}

  getHistory(queryStr) {
    return this.http.get<Overview>(`/v0/historical-view?${queryStr}`);
  }
}
