import {Provider} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { AuthenticationInterceptor } from './authentication.interceptor';
import { ErrorInterceptor } from './error.interceptor';

export const interceptorProviders: Array<Provider> = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
];
