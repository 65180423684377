import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guard/auth.guard';
import { ShellComponent } from './../layout/containers/shell/shell.component';
import { HistoryComponent } from './container/history.component';

const routesHistory: Route[] = [
  {
    path: '',
    component: ShellComponent,
    canActivateChild: [AuthGuard],
    children: [{ path: '', component: HistoryComponent, pathMatch: 'full' }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routesHistory)],
  exports: [RouterModule]
})
export class HistoryRoutingModule {}
