import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LayoutModule } from './../layout/layout.module';
import { SharedModule } from './../shared/shared.module';
import { HistoryListingComponent } from './components/history-listing/history-listing.component';
import { HistoryComponent } from './container/history.component';
import { HistoryRoutingModule } from './history-routing.module';

@NgModule({
  imports: [
    CommonModule,
    HistoryRoutingModule,
    LayoutModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    AgGridModule.withComponents([]),
    BsDatepickerModule.forRoot()
  ],
  declarations: [HistoryComponent, HistoryListingComponent],
  providers: [CurrencyPipe]
})
export class HistoryModule {}
