import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faComment,
  faDollarSign,
  faEnvelope,
  faFileExport,
  faFlag,
  faSave,
  faTimesCircle,
  faTrashAlt,
  faEuroSign
} from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

export function iconsInitializer(): () => Promise<void> {
  return () =>
    new Promise<void>(resolve => {
      library.add(
        faSearch,
        faFlag,
        faBell,
        faEnvelope,
        faCalendar,
        faClipboard,
        faComment,
        faDollarSign,
        faTrashAlt,
        faFileExport,
        faCalendar,
        faClipboard,
        faComment,
        faChevronLeft,
        faChevronRight,
        faSave,
        faTimesCircle,
        faEuroSign
      );

      resolve();
    });
}
