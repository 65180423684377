import { CurrencyPipe } from '@angular/common';
import { SummaryCardColor } from './../summary-card/summary-card.component';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-revenue-card',
  templateUrl: './revenue-card.component.html',
  styleUrls: ['./revenue-card.component.scss']
})
export class RevenueCardComponent implements OnInit {
  public CardValue: string;
  private IsCardAmount: boolean;
  @Input() color: SummaryCardColor;
  @Input() cardText: string;
  @Input() percentage?: string;
  @Input() showPercentage = false;
  @Input() showLoading = false;
  @Input() cardIcon = 'calendar';
  @Input() professionalRevenue: number;
  @Input() indiaRevenue: number;
  @Input() billable: number;

  @Input() set cardValue(value: string) {
    this.CardValue = value;
  }
  @Input() set isCardAmount(value: boolean) {
    this.IsCardAmount = value;
  }
  constructor(private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
  }

  get showCardValue() {
    return this.IsCardAmount
      ? this.currencyPipe.transform(this.CardValue, 'EUR', 'symbol-narrow', '0.2-2')
      : this.currencyPipe.transform(this.CardValue, '', '', '0.0-0');
  }

  get billableCardValue() {
    return this.currencyPipe.transform(this.billable, '', '', '0.0-0');
  }

}
